import { AccountCircle, CommentOutlined, FavoriteBorder, SendOutlined } from "@mui/icons-material";
import { Grid, IconButton, TextField, Typography, CircularProgress, Link } from "@mui/material";
import React, { useState } from "react";
import { usernames } from "../utils/stubs";


const Post = ({ post, color, index, updatePost}) => {
  
  const [comment, setComment] = useState({username:'', comment:''});
  const [loader, setLoader] = useState(false);

  const likePost=async ()=>{
    post.likes+=1;
    await updatePost(post,index);
  }

  const handleCommentChange = (event) =>{
    let tempComment ={username:'', comment:comment.comment}
    tempComment.comment = event.target.value;
    setComment(tempComment);
  }

  const handleCommentAdd =async() =>{
    try {
      setLoader(true)
      comment.username = usernames[Math.floor(Math.random()*10)];
      post.comments.push(comment)
      await updatePost(post,index);
      setComment({username:'',comment:''});
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
   
  }
  

  return (
    
    <Grid item xs={12} container direction="row" style={{background:color, padding:'6px 0px 8px 0px'}}>
    <Grid
      xs={1}
      container
      direction="column"
      alignItems="flex-end"
      justifyContent="flex-start"
      style={{ padding: "10px 32px 0px 0px" }}
    >
      <AccountCircle color='secondary' fontSize="large" />
    </Grid>
    <Grid
      item
      xs={11}
      container
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Grid item container direction='column' xs={12}>
      <Typography variant="overline" color='black'>{post.username}</Typography>
      <Typography variant="caption" color='GrayText'>
        Posted on: {new Date(post.postedDate).toDateString()}
      </Typography>
      <Typography variant='h5' style={{padding:'8px 0px 0px 0px'}}>
        {post.title}
      </Typography>
      </Grid>
      
      <Grid
        item
        container
        xs={9}
        direction="column"
        style={{ padding: "10px 0px 10px 0px" }}
      >
        {post.imageLink&&post.imageLink.trim().length>0&&
        <Grid item container xs={11}>
          <a
            href={
              post.profileLink
            }
            target='_blank'
          >
            <img
              alt=""
              style={{ height: "70vh", width: "50vw" }}
              src={
                post.imageLink
              }
            />
          </a>
        </Grid>}
        {((!post.imageLink)||post.imageLink.trim().length<1)&&post.profileLink.length>0&&
        <Grid item container direction='row' xs={1} style={{padding:'4px 0px 0px 0px'}}>
          <a href={post.profileLink} target='_blank'>
            {post.profileLink}
          </a>
        </Grid>
        }
        
        <Grid item container direction='row' xs={1} style={{padding:'4px 0px 0px 0px'}}>
         
          <IconButton children={<FavoriteBorder color='secondary' />} onClick={likePost}/>
          
          <Typography variant='overline' color='secondary' alignSelf='flex-end' >
            {post.likes}
          </Typography>
          <CommentOutlined alignSelf='flex-end' color='secondary' style={{padding:'8px 0px 0px 10px'}}/>
          
          <Typography variant='overline' color='secondary' alignSelf='flex-end' style={{padding:'0px 0px 0px 7px'}}>
            {post.comments.length}
          </Typography>
        </Grid>
        <Grid item container>
        <Typography variant='subtitle1' style={{padding:'8px 0px 0px 0px', wordBreak:'break-word'}}>
        {post.content}
      </Typography>
        </Grid>
      </Grid>
      <Grid item  container xs={9} direction='column' style={{padding:'8px 0px 0px 0px'}}>
        <Typography variant='caption'> Comments </Typography>
          <Grid  container xs={12}  style={{maxHeight:200, width:'100%', overflowY:'auto', padding:'0px 0px 6px 0px'}}>
              {post.comments.map(comment=>(
                  <Grid item container xs={12} direction='row' style={{padding: '8px 0px 4px 0px'}}>
                    <AccountCircle size={10} color='secondary'/>
                      <Typography style={{fontWeight:'bolder',padding:'0px 0px 0px 4px'}}>{comment.username}:</Typography>
                      <Typography style={{wordBreak:'break-word', padding:'0px 0px 0px 8px'}}>
                          {comment.comment}
                      </Typography>
                  </Grid>
              ))}
              
          </Grid>
          <Grid item container direction='row'>
            <Grid item xs={11}>
            <TextField value={comment.comment} onChange={handleCommentChange} placeholder="Add a comment" size='small' fullWidth/>
            </Grid>
            <Grid item container direction='column' justifyContent='center' xs={1} >
              {loader?
              <CircularProgress size ={24} color='secondary' style={{margin:'0px 0px 0px 8px'}}/>:
              <IconButton onClick={handleCommentAdd} children={<SendOutlined color='secondary'/>} />}
            </Grid>
          </Grid>
          
      </Grid>
    </Grid>
  </Grid>

   
  );
};

export default Post;
