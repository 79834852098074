import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { errorMessages, postStub } from '../utils/stubs';

class AddPost extends React.Component{
    
    constructor(props){
        super(props);
        this.state={
            post:{
                title:'',
                username:'',
                content: '',
                imageLink: '',
                profileLink: '',
                postedDate: new Date(),
                likes:0,
                comments:[]
            },
            refresh:true,
            error:'',
            loader:false,
        }
    }

    

    handlePostChange =(event,id)=>{
        let tempError =this.state.error;
        if(this.state.error.trim().length>1){
            tempError = '';
        }
        let tempPost = this.state.post;
        tempPost[id] = event.target.value;
        this.setState({post:tempPost, error: tempError});
    }
    handleImageLinkChange =(event)=>{
        let tempPost = this.state.post;
        tempPost.imageLink = event.target.value;
        this.setState({post:tempPost});
    }
    handleImageRefChange =(event)=>{
        let tempPost = this.state.post;
        tempPost.imageLink = event.target.value;
        this.setState({post:tempPost});
    }
    handlePostAddClick=async()=>{
        let tempError=''
        if(this.state.post.title.trim().length<1){
            tempError = `${errorMessages.fieldMissingError} in Title`
            this.setState({error:tempError})
        }
        else if(this.state.post.content.trim().length<1){
            tempError = `${errorMessages.fieldMissingError} in Write Something`
            this.setState({error:tempError})
        }
        else{
        this.setState({loader:true})
        let res =await this.props.addPost(this.state.post);
        
        if(res){
            this.setState({post:{...postStub}});
        }
        else{
            this.setState({error: errorMessages.internalServerError})
        }
        this.setState({refresh:!this.state.refresh, loader:false})
        }
    }

    render(){
        return(
            <Grid container direction='row'>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                            What's on your mind?
                    </Typography>
                </Grid>
                <Grid item container xs={12} direction='column'>
                    <Typography variant='overline' color='CaptionText'>
                        Give your post a title
                    </Typography>
                    <TextField multiline value={this.state.post.title} onChange={(event)=>this.handlePostChange(event, 'title')}/>
                </Grid>
                <Grid item container xs={12} direction='column'>
                    <Typography variant='overline' color='CaptionText'>
                        Write something
                    </Typography>
                    <TextField multiline value={this.state.post.content} onChange={(event)=>this.handlePostChange(event, 'content')}/>
                </Grid>
                <Grid item container xs={12} direction='column'>
                    <Typography variant='overline' color='CaptionText'>
                        Catch some attention with an Image (URL)
                    </Typography>
                    <TextField size='small' value={this.state.post.imageLink} onChange={(event)=>this.handlePostChange(event, 'imageLink')}/>
                </Grid>
                <Grid item container xs={12} direction='column'>
                    <Typography variant='overline' color='CaptionText'>
                        Want to link your Page? (URL)
                    </Typography>
                    <TextField size='small' value={this.state.post.profileLink} onChange={(event)=>this.handlePostChange(event, 'profileLink')}/>
                </Grid>
                <Grid item container xs={12} style={{padding:'16px 0px 0px 0px'}}>
                {this.state.error.length>0?
                <Typography variant='caption' color='error' style={{padding: '2px 0px 4px 0px'}}>
                    {this.state.error}
                </Typography> :null}
                {this.state.loader?
                <Grid item container justifyContent='center'>
                <CircularProgress size={32} color='secondary'/>
                </Grid>:
                <Button variant='contained' onClick={this.handlePostAddClick} fullWidth size='large' color='secondary'>
                    Add Post
                </Button>
                }
                
                </Grid>
            </Grid>
        )
    }
}

export default AddPost;