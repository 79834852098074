import { AccountCircle } from '@mui/icons-material';
import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import React from 'react';
import Posts from './Posts';


class AppShell extends React.Component{
    constructor(props){
        super(props);
        this.state={
            refresh:false
        }
    }

    render(){
        return(
            <Grid container direction='row' style={{height:'100%'}}>
                <Grid xs={12} item direction='column' container style={{ width:'100%'}}>
                
                    <AppBar position='static' style={{background:'#A7C7E7'}}>
                        <Toolbar>
                            <Grid container direction='row' width='100%'>
                            <Grid  xs={11} justifyContent = 'flex-start'>
                            <Typography variant ='h4' component='div' color='secondary'>
                                C-Flare
                            </Typography>
                            </Grid>
                            <Grid xs={1} alignItems='center' direction ='row' justifyContent ='flex-end'>
                                <Grid container alignItems='center'  justifyContent ='center'>
                                <AccountCircle  color='secondary'/>
                                 </Grid>   
                                 <Grid container alignItems='center'  justifyContent ='center'>
                                <Typography align='left' variant ='caption' color='secondary'>
                                    Varun Venkatesh
                                </Typography>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    
                </Grid>
                <Grid  item direction='row' container xs={12} style={{padding:8, width:'100%',overflow:'hidden', height:'100%'}}>
                      
                        <Posts/>
                       
                    </Grid>
                </Grid>
        )
    }
}

export default AppShell;