
import './App.css';
import AppShell from './components/AppShell';

function App() {
  return (
    <AppShell/>
  );
}

export default App;
