import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide, Typography} from "@mui/material";

import React, { useEffect, useState } from "react"
import Post from "./Post";
import AddPost from "./AddPost";
import { devUrl, localURL, relatedPosts, usernames } from "../utils/stubs";
import RelatedPosts from "./RelatedPosts";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Posts =()=>{
    const [posts, setPosts] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [loader, setLoader] =useState(true);
    const [postConfirmDialog, setPostConfirmationDialog] =useState(false);

    useEffect(()=>{  
        if(posts.length===0){
            getPostFromAPI();
        }
    },[])
    const getPosts = async ()=>{
        try {
            const resp = await fetch(
                devUrl,
                {method:"GET", headers:{"Content-type":"application/json"}}
            );
            if(resp.status!==200){
                setLoader(false);
                setPosts([])
                return false;
            }
            return resp.json()
        } catch (error) {
            
            throw error;
        }
        
    }
    const getPostFromAPI = async ()=>{
        try {
            let res = await getPosts();
            if(!res||res.length===0){
                return false;
            }
            
            else{
                setPosts(res); 
                setLoader(false);
                return true;
            }
            
        } catch (error) {
            return false;
        }
        
    }

    const handlePostConfirmClose =()=>{
        setPostConfirmationDialog(false);
    }

    const handlePostConfirmCheckout =()=>{
        setPostConfirmationDialog(false);
        let div = document.getElementById("scrollable_div")
        div.scrollTop = div.scrollHeight;
    }

    const addPost = async (post)=>{
        try {
            const resp = await fetch(
                devUrl,
                {
                    method:"POST",
                    body:JSON.stringify(post),
                    headers:{"Content-type":"application/json"}
                }
            );
            
            return resp.json()
        } catch (error) {
            throw error;
        }
        
    }

    const addPostViaAPI = async (post)=>{
        try {
            post.username = ''+usernames[Math.floor(Math.random()*10)]+(posts.length+1); 
            await addPost(post); 
            posts.push(JSON.stringify(post));    
            setPosts(posts);
            setPostConfirmationDialog(true);
            return true;
        } catch (error) {
            return false;
        }
        
    }
    const updatePost = async (post)=>{
        try {
            const resp = await fetch(
                devUrl,
                {
                    method:"PUT",
                    body:JSON.stringify(post),
                    headers:{"Content-type":"application/json"}
                }
            );
            
            return resp.json()
        } catch (error) {
            throw error;
        }
        
    }

    const updatePostViaAPI = async (post,index)=>{
        try {
            
            await updatePost(post); 
            posts[index] = JSON.stringify(post);  
            setPosts(posts);
            setRefresh(!refresh);
            return true;
        } catch (error) {
            return false;
        }
        
    }
   

    return(
        
        <Grid container direction='row' style={{ border:'1px solid gray', borderBlockColor:'WindowFrame', padding:8, borderRadius:'1%', height:'100%'}}>
            <Dialog
            open={postConfirmDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handlePostConfirmClose}
            >
            <DialogTitle>{"Awesome! You have just added a new post"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="post_confirm_dialog">
                Congratulations on your post! See who has checked it out!
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handlePostConfirmClose}>Disagree</Button>
            <Button onClick={handlePostConfirmCheckout}>Check it out</Button>
            </DialogActions>
        </Dialog>
            {!loader?
            <Grid id='scrollable_div'  item xs={9} direction='column' style={{maxHeight:'88vh',overflowY:'auto'}}>
            {posts&&posts.length>0?posts.map((post,index)=>(
                <Post post={JSON.parse(post)} color={index%2===0?'whitesmoke':'white'} index={index} updatePost={updatePostViaAPI}/>
            )):
            <Grid  item container xs={9} direction='column' alignItems='center' justifyContent='center' alignSelf='center' style={{maxHeight:'88vh',overflowY:'auto', margin:'40px 0px 0px 0px'}}>
                <Typography variant ='h3' color='InfoText'>No posts found :(</Typography>
            </Grid>
            }
            </Grid>:
             
            <Grid  item container xs={9} direction='column' alignItems='center' justifyContent='center' style={{maxHeight:'88vh',overflowY:'auto'}}>
                {loader?
                <CircularProgress size={40} color='secondary'/>:null}
               
            </Grid>}
            <Grid item container  xs={3} direction ='column' position='sticky' style={{padding:16}}>
                <Grid item container xs={6}>
                <AddPost addPost={addPostViaAPI}/>
                </Grid>
                <Grid item container   style={{padding:'24px 0px 0px 0px', width:'100%'}}>
                <RelatedPosts/>
                </Grid>
            </Grid>
        </Grid>
        
    )
}

export default Posts;