export const usernames =["Adam", "Martin", "Varun","Kelly", "Julia", "Damon", "Joshua", "Pam", "Rachel", "Stuti", "Peter"]

export const postStub = {
    title:'',
    username:'',
    content: '',
    imageLink: '',
    profileLink: '',
    postedDate: new Date(),
    likes:0,
    comments:[]
}

export const errorMessages ={
    internalServerError: "There was a problem with the connection. Try again later",
    fieldMissingError: "Don't be shy. Add some content",
}

export const devUrl='https://cloudflare-serverless.gowda-v8237.workers.dev'

export const localURL = 'http://127.0.0.1:8787'

export const relatedPosts =[
    {
        imageLink:'https://images.unsplash.com/photo-1535930891776-0c2dfb7fda1a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
        profileLink:'https://unsplash.com/photos/MoDcnVRN5JU'
    },
    {
        imageLink:'https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=781&q=80',
        profileLink:'https://unsplash.com/photos/MqT0asuoIcU'
    },
    {
        imageLink:'https://images.unsplash.com/photo-1508789454646-bef72439f197?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
        profileLink:'https://unsplash.com/photos/ZlJ6qFInqQE'
    },
    {
        imageLink:'https://images.unsplash.com/photo-1558929996-da64ba858215?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80',
        profileLink:'https://unsplash.com/photos/z7rcwqCi77s'
    },
    {
        imageLink:'https://images.unsplash.com/photo-1519583272095-6433daf26b6e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
        profileLink:'https://unsplash.com/photos/IE9PKXWpRGU'
    },
    {
        imageLink:'https://images.unsplash.com/photo-1594007654729-407eedc4be65?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=728&q=80',
        profileLink:'https://unsplash.com/photos/kfDsMDyX1K0'
    }
]