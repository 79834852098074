import { Grid, Typography } from '@mui/material';
import React from 'react';
import { relatedPosts } from '../utils/stubs';

class RelatedPosts extends React.Component {
    render(){
        return(
            
                
                <Grid item container xs={12} direction='row'>
                    <Grid item container xs={12}>
                    <Typography variant='subtitle1'>
                    Related Posts
                </Typography>
                    </Grid>
                    <Grid item container xs={12} columnSpacing={2} style={{padding: "8px 0px 0px 0px"}}>
                {relatedPosts.map((post)=>(
                    <Grid item  >
                        <a href = {post.profileLink} target='_blank'>
                            <img src={post.imageLink} style={{height:'11vh', width:'10vw'}}/>
                        </a>
                    </Grid>
                ))}
                </Grid>
                </Grid>
           
        )
    }
}

export default RelatedPosts;